<template>
  <div class="Switch-dom">
    <van-field
      readonly
      :required="required"
      :name="label"
      :label="label"
      placeholder="请选择"
      input-align="right"
    >
      <template #input>
        <van-switch v-model="switchVal" active-color='#fff' inactive-color='#fff' size="25px"/>
      </template>
    </van-field>  
  </div> 
</template>
<script>
/**
 * value：值
 * label：标题
 * required:是否必填
 */
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      switchVal : false
    };
  },
  watch: {
    switchVal:{
      handler(newVal){
          this.$emit("update:value",newVal)
          this.$emit("switchValChange",newVal)
      },
      deep:true
    },
    value:{
      handler(newVal){
        this.switchVal = newVal
      },
      deep:true
    }
  },
  created() {
    // console.log(typeof this.value)
    this.switchVal = this.value
  },
  methods: {
  },
};
</script>
<style lang='scss' scoped>
.Switch-dom{
    /deep/{
    // 修改switch的原始样式
    .van-switch{
        border: 0.02667rem solid #D9D9D9!important;
        &:after{
          content: '否';
          font-size: .5em;
          color: #D9D9D9;
          position: absolute;
          top: 50%;
          right: .6em;
          transform: translateY(-40%);
          -webkit-transform: translateY(-40%);

        }
        .van-switch__node{
          background: #D9D9D9!important;
          top: .06em;
          left: .06em;
          width: .9em;
          height: .9em;
          box-shadow: none;
          transition: all .3s;
        }
      }
      .van-switch--on{
        border: 0.02667rem solid #138DD9!important;
          &:after{
            content: '是';
            color: #138DD9;
            right: auto;
            left: .6em;
          }
        .van-switch__node{
          background: #138DD9!important;
          box-shadow: none;
          width: .9em;
          height: .9em;
          
        }
      }
    .van-field__label{
      width: 7.2em;
    }
  }

}

</style>